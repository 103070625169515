* {
  box-sizing: border-box;
  margin-top: 0;
  -webkit-tap-highlight-color: transparent;
}

html {
  font-size: $base-font-size;
}

body {
  font-family: "Lato", sans-serif;
  background: #ecf0f1;
  font-size: 1.5rem;
  line-height: 1;

  @include media(">=widescreen") {
    font-size: 1.7rem;
  }
}

h3 {
  font-size: 1.8rem;
}

h4 {
  font-size: 1.2rem;
}

img {
  max-width: 100%;
  height: auto;
}

p {
  &:last-child {
    margin-bottom: 0;
  }
}

input {
  width: 100%;
}

#wrapper {
  overflow: hidden;
  position: relative;
  min-width: 320px;

  > .container {
    height: 100vh;
  }

  > .spinner {
    position: fixed;
  }
}

.custom-button {
  text-transform: uppercase;
  color: #fff;
  display: inline-block;
  background: #00b894;
  // background: #fcad26;
  border: none;
  font-size: 1.2rem;
  padding: 8px 25px 8px 15px;
  min-width: 80px;
  position: relative;
  text-align: left;
  border-radius: 2px;

  @include media(">=tablet") {
    min-width: 100px;
    font-size: 1.4rem;
    padding: 10px 30px 10px 15px;
  }

  &:hover {
    &:after {
      width: calc(100% + 10px);
    }
  }

  &:focus {
    outline: none;
    border: none;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: -5px;
    width: 40px;
    background: rgba(#fff, 0.2);
    transform: skewX(-18deg);
    transition: width 0.2s ease-out;
  }

  [class^="icon-"] {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
    font-size: 2rem;
  }
}

.spinner {
  @extend %d-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.bg-red {
  background-color: $red;
}
