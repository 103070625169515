@mixin size($x, $y: false) {
  @if $y {
    height: $x;
    width: $y;
  } @else {
    height: $x;
    width: $x;
  }
}

@mixin d-flex {
  display: flex;
  flex-wrap: wrap;
}
