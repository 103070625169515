.logo {
  @extend %d-flex;
  justify-content: center;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 1;
  text-align: center;

  @include media("<=tablet") {
    @include size(25px);
  }

  img {
    max-width: 90%;
    @include media("<tablet") {
      max-width: 100%;
    }
  }
}

.profile {
  &-bar {
    @extend %d-flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 10px 48px;
    height: 52px;
    box-shadow: inset 0 0 4px rgba(#000, 0.2);
    width: 100%;

    @include media(">=tablet") {
      height: 100vh;
      flex-direction: column;
      padding: 20px 5px;
      background: #333;
      width: 35px;
      box-shadow: none;
    }

    @include media(">=desktop") {
      width: 42px;
      padding: 20px 8px;
    }
  }

  &-button {
    @extend %circle;
    background: $green;
    color: #fff;
    @extend %d-flex;
    justify-content: center;
    align-items: center;
    @include size(24px);
    font-size: 12px;
    line-height: 1;
    user-select: none;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  &-avatar {
    position: relative;
    z-index: 20;

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &-options {
    position: absolute;
    bottom: calc(100% + 10px);
    left: 0;
    width: 200px;
    box-shadow: 5px 5px 15px -4px rgba(166, 163, 166, 0.66),
      5px -8px 15px -2px rgba(166, 163, 166, 0.13);
    background: #fff;
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in, visibility 0.2s ease-in;

    @include media("<tablet") {
      right: 0;
      top: calc(100% + 10px);
      left: auto;
      bottom: auto;
      width: 180px;
      font-size: 1.4rem;
      line-height: 1;
    }

    &.show {
      opacity: 1;
      visibility: visible;
    }

    &:hover {
      cursor: default;
    }

    &:before {
      content: "";
      position: absolute;
      top: 100%;
      left: 7px;
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 6px 0 6px;
      border-color: #fff transparent transparent transparent;
      transition: all 0.2s linear;

      @include media("<tablet") {
        border-width: 0 6px 8px 6px;
        border-color: transparent transparent #fff transparent;
        top: -6px;
        left: calc(100% - 18px);
      }
    }
  }

  .form-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    text-align: left;
    color: rgb(70, 70, 70);
    transform: translate(-50%, -50%);
    @extend %d-flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
    background: rgba(27, 27, 27, 0.66);
    box-shadow: 5px 5px 10px 5px rgba(#ccc, 0.4),
      -5px 5px 15px 5px rgba(#ccc, 0.4);
  }

  &-list {
    @extend %list-reset;
    color: #000;
    padding: 15px 0;
    line-height: 1;
    overflow: hidden;
    text-align: center;

    @include media(">=tablet") {
      padding: 25px 0;
    }

    @include media(">=widescreen") {
      font-size: 1.5rem;
    }

    a {
      text-decoration: none;
      color: #000;

      &:hover {
        color: #000000;
      }
    }

    button {
      padding: 5px 15px 7px;
      border-radius: 5px;
      border: 1px solid #ccc;
      background: transparent;
      line-height: 1;
      transition: background 0.2s linear;

      &:hover {
        background: rgba(#ccc, 0.8);
      }
    }

    li {
      padding: 5px 10px;
    }

    .image-block {
      @include size(60px);
      @extend %circle;
      @extend %d-flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      background: $green;
      font-size: 2rem;
      text-transform: uppercase;
      font-weight: 700;
      color: #ffffff;
      margin-bottom: 8px;
      position: relative;
      z-index: 2;
      transition: color 0.2s linear;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      user-select: none;

      &:hover {
        cursor: pointer;
        color: $green;

        .edit-button {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    .edit-button {
      @extend %d-flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 4;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(#000, 0.5);
      color: #fff;
      font-size: 1.5rem;
      line-height: 1;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s linear, visibility 0.3s linear;
      user-select: none;
    }

    .email-block {
      margin-bottom: 10px;
      font-size: 1.3rem;
    }

    .username-block {
      margin-bottom: 8px;
    }
  }
}

#edit-profile-form-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  @extend %d-flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  background: rgba(#000, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s linear;

  &.form-visible {
    opacity: 1;
    visibility: visible;
  }
}

.edit-profile-form {
  background: #fff;
  border-radius: 5px;
  position: relative;
  padding-top: 40px;
  width: 280px;

  .spinner {
    right: 8px;
    left: auto;

    .MuiCircularProgress-root {
      height: 20px !important;
      width: 20px !important;
      color: $white !important;
    }
  }

  @include media(">=desktop") {
    width: 450px;
  }

  input:not([type="file"]) {
    padding: 5px 10px;
    flex: 1;
    margin-left: 5px;
    width: auto;

    @include media(">=tablet") {
      margin-left: 10px;
    }
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;

    @include media(">=tablet") {
      top: 13px;
      right: 13px;
    }

    svg {
      font-size: 2rem;
      line-height: 1;
      transition: color 0.2s linear;

      @include media(">=desktop") {
        font-size: 2.8rem;
      }

      &:hover {
        cursor: pointer;
        color: $green;
      }
    }
  }

  .password-block {
    .block-content {
      height: 0;
      transition: height 0.2s linear;
      overflow: hidden;
    }

    &.block-active {
      .block-content {
        height: 80px;
      }

      .block-header {
        .icon-holder {
          transform: rotateZ(180deg);
        }
      }
    }

    .input-group {
      padding: 0 10px;
      margin-bottom: 10px;

      @include media(">=desktop") {
        padding: 0 20px;
        margin-bottom: 15px;
      }
    }
  }

  .block-header {
    padding: 10px 0;
    position: relative;
    margin: 0 10px 10px;

    @include media(">=desktop") {
      margin: 0 20px 10px;
    }

    &:hover {
      cursor: pointer;
    }

    .icon-holder {
      position: absolute;
      top: 10px;
      right: 0;
      transform: rotateZ(0);
      transition: transform 0.2s linear;
    }
  }

  .input-group {
    padding: 20px;
    font-size: 1.3rem;
    @extend %d-flex;
    align-items: center;
    padding: 15px 10px;

    @include media(">=desktop") {
      padding: 25px 20px 10px;
    }

    @include media(">=tablet") {
      font-size: 1.4rem;
    }
  }

  label {
    display: inline-block;
    text-transform: uppercase;
  }

  .button-group {
    text-align: center;
    padding: 0 20px 30px;

    @include media(">=desktop") {
      padding: 20px 20px 40px;
    }

    .button {
      margin: 0 5px 10px;
    }
  }

  .input-file {
    position: relative;
    padding-bottom: 20px;
    @include size(100px);
    @extend %circle;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 auto;
    background: $green;
    color: #ffffff;
    font-size: 2.5rem;
    line-height: 1;
    font-weight: 700;
    transition: color 0.2s linear;

    @include media(">=widescreen") {
      @include size(120px);
    }

    &:hover {
      color: $green;
      cursor: grab;

      .icon-holder {
        opacity: 1;
        visibility: visible;
        color: #fff;
      }
    }

    input[type="file"] {
      opacity: 0;
      visibility: hidden;
    }

    .info-block {
      text-align: center;
      pointer-events: none;
      padding: 10px;

      strong {
        display: block;
        padding-top: 10px;
        margin-bottom: 10px;
      }
    }

    label {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      @extend %d-flex;
      justify-content: center;
      align-items: center;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;

      &:hover {
        cursor: grab;
        color: $green;
      }
    }

    i {
      font-size: 2.3rem;
    }

    .icon-holder {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      @extend %d-flex;
      @extend %circle;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.5);
      pointer-events: none;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.2s linear, visibility 0.2s linear, color 0.2s linear;
      color: $green;
      z-index: 5;
    }
  }
}

.password-update-message {
  position: relative;
  margin: 0 10px 10px;
  padding-top: 10px;
  font-size: 1.5rem;
  line-height: 1;

  @include media(">=desktop") {
    margin: 0 20px 10px;
  }

  &.success {
    .success-message {
      color: $green;
      opacity: 1;
      visibility: visible;
    }
  }

  &.failure {
    .failure-message {
      color: $red;
      opacity: 1;
      visibility: visible;
    }
  }
  .success-message,
  .failure-message {
    opacity: 0;
    visibility: hidden;
  }
}
