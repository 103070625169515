%list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

%circle {
  border-radius: 50%;
  overflow: hidden;
}
%d-flex,
.d-flex {
  display: flex;
  flex-wrap: wrap;
}

%ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
