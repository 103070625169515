@font-face {
  font-family: "icomoon";
  src: url("/assets/fonts/icomoon.eot?994d5b");
  src: url("/assets/fonts/icomoon.eot?994d5b#iefix") format("embedded-opentype"),
    url("/assets/fonts/icomoon.ttf?994d5b") format("truetype"),
    url("/assets/fonts/icomoon.woff?994d5b") format("woff"),
    url("/assets/fonts/icomoon.svg?994d5b#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cheveron-down:before {
  content: "\e906";
}
.icon-camera:before {
  content: "\e904";
}
.icon-arrow-right:before {
  content: "\e905";
}
.icon-log-in:before {
  content: "\e902";
}
.icon-photos:before {
  content: "\e903";
}
.icon-facebook:before {
  content: "\e900";
}
.icon-google:before {
  content: "\e901";
}
